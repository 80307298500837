var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar", attrs: { data: _vm.backgroundColor } },
    [
      _c(
        "div",
        { staticClass: "sidebar-wrapper", attrs: { id: "style-3" } },
        [
          _c(
            "div",
            { staticClass: "logo" },
            [
              _c(
                "router-link",
                {
                  staticClass: "simple-text logo-mini",
                  attrs: {
                    to: "/",
                    "aria-label": _vm.$t("goBackToDashboard"),
                    title: _vm.$t("goBackToDashboard")
                  }
                },
                [
                  _c("BaseImage", {
                    attrs: { source: _vm.miniLogo, size: "", model: "" }
                  })
                ],
                1
              ),
              _c(
                "router-link",
                { staticClass: "simple-text", attrs: { to: "/" } },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            ],
            1
          ),
          _vm._t("default"),
          _c(
            "ul",
            { staticClass: "nav" },
            [
              _vm._t("links", function() {
                return _vm._l(_vm.allowedSidebarLinks, function(link) {
                  return _c(
                    "sidebar-link",
                    {
                      key: link.name,
                      staticClass: "menuItem",
                      attrs: { to: link.path, name: link.name, icon: link.icon }
                    },
                    [
                      link.icon ? _c("i", { class: link.icon }) : _vm._e(),
                      _vm._v(" " + _vm._s(link.name) + " "),
                      link.submenu
                        ? _c(
                            "ul",
                            { staticClass: "submenu" },
                            _vm._l(link.submenu, function(link2) {
                              return _c("sidebar-link", {
                                key: link2.name,
                                staticClass: "submenuItem",
                                attrs: {
                                  to: link2.path,
                                  name: link2.name,
                                  icon: link2.icon
                                }
                              })
                            }),
                            1
                          )
                        : _vm._e()
                    ]
                  )
                })
              })
            ],
            2
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }