var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifications" },
    [
      _c(
        "transition-group",
        { attrs: { name: _vm.transitionName, mode: _vm.transitionMode } },
        _vm._l(_vm.notifications, function(notification) {
          return _c(
            "notification",
            _vm._b(
              {
                key: notification.timestamp.getTime(),
                attrs: { "click-handler": notification.onClick },
                on: { close: _vm.removeNotification }
              },
              "notification",
              notification,
              false
            )
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }