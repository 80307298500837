var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    class: [
      "icon",
      "" + _vm.iconsGroup,
      "icon-" + _vm.icon,
      "" + _vm.icon,
      "icon" + _vm.size
    ],
    on: { click: _vm.handleClick }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }