var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "list text-center" },
    [
      _c("li", [
        _c(
          "div",
          { class: ["itemInner", "head", { extended: _vm.supportsPhoto }] },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("grip")))]),
            _vm.supportsPhoto
              ? _c("span", [_vm._v(_vm._s(_vm.$tc("photo")))])
              : _vm._e(),
            _c("span", { staticClass: "text-left" }, [
              _vm._v(_vm._s(_vm.$t("title")))
            ]),
            _c("span", [_vm._v(_vm._s(_vm.$t("position")))]),
            _c("span", [_vm._v(_vm._s(_vm.$t("edit")))]),
            _c("span", [_vm._v(_vm._s(_vm.$t("remove")))])
          ]
        )
      ]),
      _c(
        "Draggable",
        {
          model: {
            value: _vm.mutatableItems,
            callback: function($$v) {
              _vm.mutatableItems = $$v
            },
            expression: "mutatableItems"
          }
        },
        _vm._l(_vm.items, function(item) {
          return _c("li", { key: "slideshow" + item.id, staticClass: "item" }, [
            _c(
              "div",
              { class: ["itemInner", { extended: _vm.supportsPhoto }] },
              [
                _c(
                  "span",
                  { staticClass: "grip" },
                  [
                    _c("BaseIcon", {
                      attrs: { "icons-group": "fas", icon: "fa-grip-vertical" }
                    })
                  ],
                  1
                ),
                _vm.supportsPhoto
                  ? _c(
                      "div",
                      { staticClass: "photo" },
                      [
                        item.photo
                          ? _c("BaseImage", {
                              attrs: { source: item, size: "thumb" }
                            })
                          : _c(
                              "span",
                              [
                                _c("BaseIcon", {
                                  staticClass: "icon iconImage",
                                  attrs: {
                                    "icons-group": "far",
                                    icon: "fa-image",
                                    size: "l"
                                  }
                                })
                              ],
                              1
                            )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "text-left" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/" + _vm.model + "/" + item.id } },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v(_vm._s(item.name))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c("span", [_vm._v(_vm._s(item.position))]),
                _c(
                  "router-link",
                  { attrs: { to: "/" + _vm.model + "/" + item.id } },
                  [
                    _c("BaseIcon", {
                      staticClass: "iconEdit",
                      attrs: { "icons-group": "far", icon: "fa-edit" }
                    })
                  ],
                  1
                ),
                _c("BaseIcon", {
                  staticClass: "iconDelete",
                  attrs: { "icons-group": "fas", icon: "fa-ban" },
                  on: {
                    click: function($event) {
                      return _vm.remove(item.id)
                    }
                  }
                })
              ],
              1
            )
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }