<script>
import LazyLoad from '@/directives/LazyLoad'
import PdfLogo from '@/assets/images/pdf-logo.png'

export default {
  name: 'BaseImage',
	directives: {
		lazyLoad: LazyLoad,
	},
	props: {
		source: {
			type: [Object, String],
			default: () => {},
		},
		size: {
			type: String,
			default: 'medium',
		},
		noImageTitle: {
			type: String,
			default: 'NO IMAGE',
		},
		customText: {
			type: String,
			default: '',
		},
		background: {
			type: Boolean,
			default: false,
		},
		fill: {
			type: Boolean,
			default: false,
    },
		fit: {
			type: Boolean,
			default: false,
    },
    isPdf: {
      type: Boolean,
      default: false
    },
    model: {
      type: String,
      default: 'photo'
    },
    alt: {
      type: String,
      default: 'no alt available'
    }
	},
  data() {
    return {
      pdfLogo: PdfLogo
    }
  },
}
</script>
c
<template>
  <div
    v-if="background && source"
    :style="`background-image: url('${source[model][size]}')`"
    class="background"
  />
  <figure
    v-else-if="!background && source"
    :key="source.id"
    v-lazyload
    :class="['wrap', fill && 'fill', fit && 'fit']"
  >
    <span class="spinner" />
    <img
      :data-url="isPdf ? pdfLogo : model && size ? source[model][size] : source"
      :alt="model ? source[model].alt : alt"
      class="item"
    >
  </figure>
  <div
    v-else
    class="noImage"
  >
    <BaseIcon
      icon="image"
      class="noImageIcon"
    />
    <BaseLabel
      class="label"
      float
    >
      {{
        customText || $t('noImage')
      }}
    </BaseLabel>
  </div>
</template>

<style scoped lang="sass">
@import '~@/assets/sass/design.sass'

.item
  position: relative
  display: block
  max-width: 100%
  max-height: 100%
  margin: 0 auto
  opacity: 0
  transition: opacity .6s ease-in-out
.spinner
  opacity: 0.8
  transition: opacity .6s ease-in-out
  &::before
    position: absolute
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%
    content: ''
    background: $green-blue-purple-gradient
    animation: fade 2s ease-in-out infinite alternate-reverse
.wrap
  display: flex
  align-items: center
  width: 100%
  max-height: 100%
  margin: 0
  text-align: center
  &.loaded
    .item
      opacity: 1
    .spinner
      pointer-events: none
      opacity: 0
  &.fill
    overflow: hidden
    .item
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
  &.fit
    .item
      object-fit: contain
      object-position: center
.noImage
  position: relative
  display: inline-block
  &Icon
    width: 150px
    max-width: 100%
    height: 100%
    opacity: 0.5
  .label
    margin-top: $base-spacing
.background
  position: absolute
  top: 0
  right: 0
  left: 0
  height: 100%
  background-position: center
  background-size: cover


@keyframes fade
  from
    opacity: 0.3
  to
    opacity: 0.7
</style>
