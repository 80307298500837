var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "navbar navbar-expand-lg navbar-absolute",
      class: { "bg-white": _vm.showMenu, "navbar-transparent": !_vm.showMenu }
    },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c("div", { staticClass: "navbar-wrapper" }, [
            _c(
              "div",
              {
                staticClass: "navbar-toggle d-inline",
                class: { toggled: _vm.$sidebar.showSidebar }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "navbar-toggler",
                    attrs: {
                      type: "button",
                      "aria-label": "Navbar toggle button"
                    },
                    on: { click: _vm.toggleSidebar }
                  },
                  [
                    _c("span", { staticClass: "navbar-toggler-bar bar1" }),
                    _c("span", { staticClass: "navbar-toggler-bar bar2" }),
                    _c("span", { staticClass: "navbar-toggler-bar bar3" })
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "navbar-brand" }, [
              _vm._v(" " + _vm._s(_vm.camelToText(_vm.routeName)) + " ")
            ])
          ]),
          _c(
            "button",
            {
              staticClass: "navbar-toggler",
              attrs: {
                type: "button",
                "data-toggle": "collapse",
                "data-target": "#navigation",
                "aria-controls": "navigation-index",
                "aria-label": "Toggle navigation"
              },
              on: { click: _vm.toggleMenu }
            },
            [
              _c("span", { staticClass: "navbar-toggler-bar navbar-kebab" }),
              _c("span", { staticClass: "navbar-toggler-bar navbar-kebab" }),
              _c("span", { staticClass: "navbar-toggler-bar navbar-kebab" })
            ]
          ),
          _c("collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showMenu,
                    expression: "showMenu"
                  }
                ],
                staticClass: "collapse navbar-collapse show"
              },
              [
                _c(
                  "ul",
                  { staticClass: "navbar-nav", class: "ml-auto" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "search-bar input-group",
                        on: {
                          click: function($event) {
                            _vm.searchModalVisible = true
                          }
                        }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link",
                            attrs: {
                              id: "search-button",
                              "data-toggle": "modal",
                              "data-target": "#searchModal"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "tim-icons icon-zoom-split"
                            })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "modal",
                      {
                        staticClass: "modal-search",
                        attrs: {
                          id: "searchModal",
                          show: _vm.searchModalVisible,
                          centered: false,
                          "show-close": true
                        },
                        on: {
                          "update:show": function($event) {
                            _vm.searchModalVisible = $event
                          }
                        }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchQuery,
                              expression: "searchQuery"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            slot: "header",
                            id: "inlineFormInputGroup",
                            type: "text",
                            placeholder: _vm.$t("search")
                          },
                          domProps: { value: _vm.searchQuery },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.searchQuery = $event.target.value
                            }
                          },
                          slot: "header"
                        })
                      ]
                    ),
                    _c(
                      "BaseDropdown",
                      {
                        staticClass: "nav-item",
                        attrs: {
                          "menu-on-right": "",
                          tag: "li",
                          "title-tag": "a"
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-toggle nav-link",
                            attrs: {
                              slot: "title",
                              href: "#",
                              "data-toggle": "dropdown",
                              "aria-expanded": "true"
                            },
                            slot: "title"
                          },
                          [
                            _c("div", {
                              staticClass:
                                "notification d-none d-lg-block d-xl-block"
                            }),
                            _c("i", {
                              staticClass: "tim-icons icon-sound-wave"
                            }),
                            _c("p", { staticClass: "d-lg-none" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("newNotifications")) + " "
                              )
                            ])
                          ]
                        ),
                        _c("li", { staticClass: "nav-link" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-item dropdown-item",
                              attrs: { href: "#" }
                            },
                            [_vm._v("Mike John responded to your email")]
                          )
                        ]),
                        _c("li", { staticClass: "nav-link" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-item dropdown-item",
                              attrs: { href: "#" }
                            },
                            [_vm._v("You have 5 more tasks")]
                          )
                        ]),
                        _c("li", { staticClass: "nav-link" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-item dropdown-item",
                              attrs: { href: "#" }
                            },
                            [_vm._v("Your friend Michael is in town")]
                          )
                        ]),
                        _c("li", { staticClass: "nav-link" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-item dropdown-item",
                              attrs: { href: "#" }
                            },
                            [_vm._v("Another notification")]
                          )
                        ]),
                        _c("li", { staticClass: "nav-link" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-item dropdown-item",
                              attrs: { href: "#" }
                            },
                            [_vm._v("Another one")]
                          )
                        ])
                      ]
                    ),
                    _c(
                      "BaseDropdown",
                      {
                        staticClass: "nav-item",
                        attrs: {
                          "menu-on-right": "",
                          tag: "li",
                          "title-tag": "a"
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-toggle nav-link",
                            attrs: {
                              slot: "title",
                              href: "#",
                              "data-toggle": "dropdown",
                              "aria-expanded": "true"
                            },
                            slot: "title"
                          },
                          [
                            _c("div", {
                              staticClass: "d-none d-lg-block d-xl-block"
                            }),
                            _c("i", { staticClass: "tim-icons icon-planet" }),
                            _c("p", { staticClass: "d-lg-none" }, [
                              _vm._v(" " + _vm._s(_vm.$t("language")) + " ")
                            ])
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "nav-link",
                            on: {
                              click: function($event) {
                                return _vm.changeLanguage("en")
                              }
                            }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-item dropdown-item",
                                attrs: { href: "#" }
                              },
                              [_vm._v("English")]
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "nav-link",
                            on: {
                              click: function($event) {
                                return _vm.changeLanguage("el")
                              }
                            }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-item dropdown-item",
                                attrs: { href: "#" }
                              },
                              [_vm._v("Ελληνικά")]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "BaseDropdown",
                      {
                        staticClass: "nav-item",
                        attrs: {
                          "menu-on-right": "",
                          tag: "li",
                          "title-tag": "a",
                          "menu-classes": "dropdown-navbar"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "dropdown-toggle nav-link",
                            attrs: {
                              slot: "title",
                              "data-toggle": "dropdown",
                              "aria-expanded": "true"
                            },
                            slot: "title"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "photo" },
                              [
                                _vm.userPhoto
                                  ? _c("BaseImage", {
                                      attrs: {
                                        source: _vm.userPhoto,
                                        size: "mini"
                                      }
                                    })
                                  : _c("img", {
                                      attrs: { src: "/img/default-avatar.png" }
                                    })
                              ],
                              1
                            ),
                            _c("b", {
                              staticClass: "caret d-none d-lg-block d-xl-block"
                            }),
                            _c(
                              "p",
                              {
                                staticClass: "d-lg-none mobileMenuTitle",
                                on: {
                                  click: function($event) {
                                    return _vm.logout()
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("logout")) + " ")]
                            )
                          ]
                        ),
                        _c("li", { staticClass: "nav-link" }, [
                          _c(
                            "span",
                            { staticClass: "nav-item dropdown-item" },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("hello")) + " "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.user.name || _vm.user.username)
                                )
                              ])
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "dropdown-divider" }),
                        _vm.user && _vm.user.id
                          ? _c(
                              "li",
                              { staticClass: "nav-link" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-item dropdown-item",
                                    attrs: {
                                      to: {
                                        name: "singleUser",
                                        params: { id: _vm.user.id }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$tc("profile")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$store.getters[
                          "permissions/checkIfUserHasPermission"
                        ]("user.read-list")
                          ? _c(
                              "li",
                              { staticClass: "nav-link" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-item dropdown-item",
                                    attrs: { to: "/users" }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$tc("user", 2)) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$store.getters[
                          "permissions/checkIfUserHasPermission"
                        ]("setting.read")
                          ? _c(
                              "li",
                              { staticClass: "nav-link" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-item dropdown-item",
                                    attrs: { to: "/settings" }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$tc("setting", 2)) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$store.getters[
                          "permissions/checkIfUserHasPermission"
                        ]("permissions.read-list")
                          ? _c(
                              "li",
                              { staticClass: "nav-link" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-item dropdown-item",
                                    attrs: { to: "/permissions" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$tc("permission", 2)) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$store.getters[
                          "permissions/checkIfUserHasPermission"
                        ]("role.read-list")
                          ? _c(
                              "li",
                              { staticClass: "nav-link" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-item dropdown-item",
                                    attrs: { to: "/roles" }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$tc("role", 2)) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$store.getters[
                          "permissions/checkIfUserHasPermission"
                        ]("social.read-list")
                          ? _c(
                              "li",
                              { staticClass: "nav-link" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-item dropdown-item",
                                    attrs: { to: "/social" }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("socialMedia")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$store.getters[
                          "permissions/checkIfUserHasPermission"
                        ]("newsletter.read-list")
                          ? _c(
                              "li",
                              { staticClass: "nav-link" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-item dropdown-item",
                                    attrs: { to: "/newsletter-subscribers" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$tc("subscriber", 2)) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "dropdown-divider" }),
                        _c(
                          "li",
                          {
                            staticClass: "nav-link",
                            on: {
                              click: function($event) {
                                return _vm.logout()
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nav-item dropdown-item" },
                              [_vm._v(_vm._s(_vm.$t("logout")))]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }