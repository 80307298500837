var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DataTable", {
    staticClass: "datatableLight",
    attrs: {
      "header-fields": _vm.headerFields,
      data: _vm.items,
      "not-found-msg":
        _vm.notFoundMessage || _vm.$t("notFound", { item: _vm.$tc("item", 2) }),
      "sort-field": _vm.sortField,
      sort: _vm.sort,
      css: _vm.datatableCss,
      "only-show-ordered-arrow": _vm.onlyShowOrderedArrow,
      "is-loading": _vm.isLoading,
      "item-key": "id"
    },
    scopedSlots: _vm._u(
      [
        {
          key: "published",
          fn: function(props) {
            return _c("div", {}, [
              _c(
                "div",
                {
                  class: [
                    "published",
                    { isPublished: props.rowData.published === 1 }
                  ],
                  attrs: {
                    title:
                      props.rowData.published === 1
                        ? _vm.$t("published")
                        : _vm.$t("unpublished")
                  }
                },
                [
                  _c("BaseIcon", {
                    attrs: { "icons-group": "far", icon: "fa-check-circle" }
                  })
                ],
                1
              )
            ])
          }
        },
        {
          key: "photo",
          fn: function(props) {
            return _c("div", {}, [
              _c(
                "div",
                { staticClass: "photoContainer" },
                [
                  props &&
                  props.rowData &&
                  props.rowData.photo &&
                  props.rowData.photo.mini
                    ? _c("BaseImage", {
                        staticClass: "photo",
                        attrs: { source: props.rowData, size: "mini", fill: "" }
                      })
                    : _c(
                        "span",
                        [
                          _c("BaseIcon", {
                            staticClass: "icon iconImage",
                            attrs: {
                              "icons-group": "far",
                              icon: "fa-image",
                              size: "l"
                            }
                          })
                        ],
                        1
                      )
                ],
                1
              )
            ])
          }
        },
        {
          key: "title",
          fn: function(props) {
            return _c("div", {}, [
              _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      class: ["title"],
                      attrs: { to: "/" + _vm.type + "/" + props.rowData.id }
                    },
                    [_vm._v(" " + _vm._s(props.rowData.name) + " ")]
                  )
                ],
                1
              )
            ])
          }
        },
        {
          key: "edit",
          fn: function(props) {
            return [
              _c(
                "router-link",
                { attrs: { to: "/" + _vm.type + "/" + props.rowData.id } },
                [
                  _c("BaseIcon", {
                    staticClass: "iconEdit",
                    attrs: { "icons-group": "far", icon: "fa-edit" }
                  })
                ],
                1
              )
            ]
          }
        },
        {
          key: "delete",
          fn: function(props) {
            return [
              _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      return _vm.remove(props)
                    }
                  }
                },
                [
                  _c("BaseIcon", {
                    staticClass: "iconDelete",
                    attrs: { "icons-group": "fas", icon: "fa-ban" }
                  })
                ],
                1
              )
            ]
          }
        },
        _vm.hasPagination
          ? {
              key: "pagination",
              fn: function() {
                return [
                  _c("Pagination", {
                    attrs: {
                      offset: _vm.offset,
                      "total-items": _vm.totalItems,
                      limit: _vm.limit,
                      "total-displayed": _vm.items ? _vm.items.length : null
                    },
                    on: { updatePagination: _vm.updatePagination }
                  })
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }