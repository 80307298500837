var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logoContainer" },
    [
      _c("BaseImage", {
        attrs: {
          source: _vm.logo,
          model: "",
          alt: _vm.title + " " + _vm.$t("logo"),
          size: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }