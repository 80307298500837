var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-us full-screen" }, [
    _c("nav", { staticClass: "navbar navbar-ct-default" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "navbar-header" },
          [
            _c(
              "router-link",
              { staticClass: "navbar-brand", attrs: { to: { path: "/" } } },
              [_vm._v(" " + _vm._s(_vm.title) + " ")]
            )
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "wrapper wrapper-full-page section content" }, [
      _c("div", {}, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-8 col-md-offset-2 text-center" }, [
              _c("h2", { staticClass: "title text-danger" }, [
                _vm._v(" " + _vm._s(_vm.$t("notFound", { item: 404 })) + " ")
              ]),
              _c("h2", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.$t("m.oopsThatPageDoesntExist")) + " ")
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }