var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AuthLayout",
    [
      _c(
        "form",
        {
          attrs: { name: "form" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleRequest.apply(null, arguments)
            }
          }
        },
        [
          _c("BaseInput", {
            attrs: {
              label: _vm.$t("newPassword"),
              placeholder: _vm.$t("enterNewPassword"),
              type: "password",
              autocomplete: "null"
            },
            model: {
              value: _vm.model.password,
              callback: function($$v) {
                _vm.$set(_vm.model, "password", $$v)
              },
              expression: "model.password"
            }
          }),
          _c("BaseInput", {
            attrs: {
              label: _vm.$t("confirmYourNewPassword"),
              placeholder: _vm.$t("enterYourNewPasswordAgain"),
              type: "password",
              autocomplete: "null"
            },
            model: {
              value: _vm.model.password_confirmation,
              callback: function($$v) {
                _vm.$set(_vm.model, "password_confirmation", $$v)
              },
              expression: "model.password_confirmation"
            }
          }),
          _c(
            "BaseButton",
            {
              staticClass: "button",
              attrs: {
                slot: "footer",
                type: "blue",
                gradient: "",
                "native-type": "submit",
                fill: ""
              },
              on: { click: function() {} },
              slot: "footer"
            },
            [_vm._v(" " + _vm._s(_vm.$t("resetPassword")) + " ")]
          )
        ],
        1
      ),
      _c("br"),
      _c("div", [_vm._v(_vm._s(_vm.$t("wantToLogin")))]),
      _c("router-link", { attrs: { to: { name: "login" } } }, [
        _vm._v(" " + _vm._s(_vm.$t("clickHereToLogin")) + " ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }